import React,  { Component, useState }from 'react';
import '../../Common/Custom.css';
import { BrowserRouter , Link, NavLink, useNavigate, withRouter  } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DvrIcon from '@mui/icons-material/Dvr';
import EventIcon from '@mui/icons-material/Event';
import MoneyIcon from '@mui/icons-material/Money';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PlaceIcon from '@mui/icons-material/Place';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import CelebrationIcon from '@mui/icons-material/Celebration';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CallIcon from '@mui/icons-material/Call';



 
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow:'none',
}));





function Form() {
  
    
  const [name, setName] = useState("");
  const [emailID, setEmail] = useState("");
  const [mobile, setMobileNumber] = useState("");
  const [remarks, setMessage] = useState("");



  let handleSubmit = async (e) => {
    e.preventDefault();
	
	 fetch
     ("https://api.dialerp.com/api/WebApi/pushCRMDealMongo/40010A1?countryCode=USA&name="+name+"&emailID="+emailID+"&mobile="+mobile+"&remarks="+remarks, {
      method: 'POST',
    } )
    .then(res => {
      
          console.log(res.response+"---data---"+res.data)
  
          if( res.data == 'success' ){

             alert("Success"); 
             
          }
      
    })
    .catch((error) => {
        console.log(error)
    })
  } 



  return( <div>
    
      
       
      
       
    <form onSubmit={handleSubmit} className="formsubmit">
       <Grid container spacing={2}>
       <Grid item xs={12}>
           <Item>
              <h3 className='sec-title'>Connect</h3>
             </Item>
         </Grid>
        
         <Grid item md={4} xs={12}>
           <Item>
              <TextField id="standard-basic" label="Name" className='inputs' variant="standard"  value={name} onChange={(e) => setName(e.target.value)} name="name" required />
          </Item>
         </Grid>  
          <Grid item md={4} xs={12}>
           <Item>
            <TextField id="standard-basic" label="Mobile" variant="standard" className='inputs' name="mobile"  value={mobile} onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ""))} required  />
          </Item>
         </Grid>
         <Grid item md={4} xs={12}>
           <Item>
            <TextField id="standard-basic" label="Email" variant="standard" className='inputs' name="emailID"  value={emailID}  onChange={(e) => setEmail(e.target.value)} required   />
            </Item>
         </Grid>
         <Grid item md={12} xs={12}>
           <Item>
           
        <TextField
          id="standard-multiline-static"
          label="Query"
          multiline
          rows={4}
          
          variant="standard" className='inputs' name="remarks" required value={remarks}  onChange={(e) => setMessage(e.target.value)}
        />

             </Item>
         </Grid>
         <Grid item xs={12}>
           <Item>
          <Button variant="contained" type="submit">Submit</Button>

             </Item>
         </Grid>
       </Grid>
         </form>

      
    </div>
);
}


export default Form;
