import React,  { Component, useState, useEffect  } from 'react';
import '../../Common/Custom.css';
import { BrowserRouter , Link, NavLink, useNavigate, withRouter  } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DvrIcon from '@mui/icons-material/Dvr';
import EventIcon from '@mui/icons-material/Event';
import MoneyIcon from '@mui/icons-material/Money';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PlaceIcon from '@mui/icons-material/Place';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import CelebrationIcon from '@mui/icons-material/Celebration';
import CallIcon from '@mui/icons-material/Call';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import Form from './Form';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow:'none',
}));

function Serialnumbergenuine() {

  
    
  const [name, setName] = useState("");
  const [emailID, setEmail] = useState("");
  const [mobile, setMobileNumber] = useState("");
  const [remarks, setMessage] = useState("");



  let handleSubmit = async (e) => {
    e.preventDefault();
	
	 fetch
     ("https://api.dialerp.com/api/WebApi/pushCRMDealMongo/40010A1?countryCode=USA&name="+name+"&emailID="+emailID+"&mobile="+mobile+"&remarks="+remarks, {
      method: 'POST',
    } )
    .then(res => {
      
      handleClickOpen2();
      //alert("Success"); 
         // console.log(res.response+"---data---"+res.data)
  
          //if( res.data == 'success' ){
           //  alert("Success"); 
            // handleClickOpen2();
             
          //}
    })
    .catch((error) => {
        console.log(error)
    })
  } 


  
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  return (
    <div className="verify-product">

<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       <div className='scan-alert'>
                <strong>Submit Form</strong>
              
       
                <form onSubmit={handleSubmit} className="formsubmit formsubmitt">
       <Grid container spacing={2}>
     
         <Grid item md={6} xs={12}>
           <Item>
              <TextField id="standard-basic" label="Name" className='inputs' variant="standard"  value={name} onChange={(e) => setName(e.target.value)} name="name" required />
          </Item>
         </Grid>  
          <Grid item md={6} xs={12}>
           <Item>
            <TextField id="standard-basic" label="Mobile" variant="standard" className='inputs' name="mobile"  value={mobile} onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ""))} required  />
          </Item>
         </Grid>
         <Grid item md={12} xs={12}>
           <Item>
            <TextField id="standard-basic" label="Email" variant="standard" className='inputs' name="emailID"  value={emailID}  onChange={(e) => setEmail(e.target.value)} required   />
            </Item>
         </Grid>
         <Grid item md={12} xs={12}>
           <Item>
           
        <TextField
          id="standard-multiline-static"
          label="Query"
          multiline
          rows={4}
          
          variant="standard" className='inputs' name="remarks" required value={remarks}  onChange={(e) => setMessage(e.target.value)}
        />

             </Item>
         </Grid>
         <Grid item xs={12}>
           <Item>
          <Button variant="contained" type="submit" color="success" onClick={handleClose}>Submit</Button>

             </Item>
         </Grid>
       </Grid>
         </form>
       
               </div>
      </Dialog>


      <Dialog show={open2}
                onHide={() => setOpen2(false)}
        open={open2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" onBackdropClick="false"
      >
       <div className='scan-alert'>
                <strong>Thanks for submitting your query.</strong>
               
        <DialogActions className='ques-button'>  

              <Button variant="contained" color="success" onClick={handleClose}>Ok</Button>
        </DialogActions>
               </div>
      </Dialog>

      <div className="product-box">
       <Card className='card-box'>
          <Card className='inner-card mb-10'>
          <Grid container spacing={2}>
          
            <Grid item md={5} xs={12}>
              <Item><img src={process.env.PUBLIC_URL + '/images/file-1660022359197.png'} alt="Slide" style={{width: '200px'}} /></Item>
            </Grid>
            <Grid item md={7} xs={12}>
              <Item>
                <div className='scan-status'><ReportProblemIcon/>Product not Fond</div>
                <Divider/>
                <div className='scan-alert scan-alert2'>
                <strong>Do you want to raise query for this?</strong>
                <Stack spacing={2} direction="row" className="stack-button-group">
                  <Button variant="contained" size="small" color="success" onClick={handleClickOpen}>Yes</Button>
                </Stack>
               </div>

              

              </Item>
            </Grid>
          </Grid>
          </Card>
          

          <Card className='inner-card mb-10'>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Item>
              <div className='helplinetitle'>Helpline For Any Query  <a href="tel:+91-9582 77 5555"><CallIcon/> +91-9582 77 5555</a></div>

              </Item>
            </Grid>
          </Grid>
          </Card>

       </Card>

       <Grid container spacing={2} className="mb-20 mb-auto button-group">          
          <Grid item xs={12}>
                <Stack spacing={2} direction="row" className="stacks-button-group">
                <HashLink smooth to="#id-1" activeclassname="active"> <Button variant="outlined">Discover</Button></HashLink>
                <HashLink smooth to="#id-2" activeclassname="active"> <Button variant="outlined">Learn</Button></HashLink>
                    <a href="https://amzn.eu/d/2laVQDS" target="_blank"><Button variant="outlined">Buy</Button></a>
                    <HashLink smooth to="#id-3" activeclassname="active"> <Button variant="outlined">Connect</Button></HashLink>
                </Stack>
          </Grid>
         </Grid>

       <Card className='inner-card discover mb-10' id="id-1">
       
          <Grid container spacing={2}>
          
            <Grid item md={6} xs={12}>
              <Item>
                  <Card>
                  <p className='video-title'>See how your favourite Ghee is prepared?</p>
                  <iframe width="94%" height="180px" src="https://www.youtube.com/embed/yRUUSoxHHs0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </Card>
                </Item>
            </Grid>
            <Grid item md={6} xs={12}>
              <Item>                
                <Card>
                  <p className='video-title'>Ananda Business Associates Awards</p>
                  <iframe width="94%" height="180px" src="https://www.youtube.com/embed/XXDx2LxF9C8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </Card>
              </Item>
            </Grid>
            <Grid item md={6} xs={12}>
              <Item>                
                <Card>
                  <p className='video-title'>Besan ke laddu with Ananda Ghee</p>
                  <iframe width="94%" height="180px" src="https://www.youtube.com/embed/Gqnc7TtmtE4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </Card>
              </Item>
            </Grid>
            <Grid item md={6} xs={12}>
              <Item>                
                <Card>
                  <p className='video-title'>Our other products</p>
                  <a href="https://ananda.in/main-category/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/ananda-products.webp'} alt="Slide" style={{width: '96%'}} /></a>
                </Card>
              </Item>
            </Grid>
            
          </Grid>
          </Card>

          <Card className='inner-card learn mb-10' id="id-2">
       
       <Grid container spacing={2}>
       
         <Grid item xs={12}>
           <Item>
              <h3 className='sec-title'>Learn</h3>
               <p className='video-title'>Ananda Ghee is an ethnic product made by our dairies with decades of experience. Our Ghee is a rich source of vitamins A and D, antioxidants, Omega 3 and CLA, essential for vision, body growth, bone metabolism and immune system.

</p>
              
             </Item>
         </Grid>
       </Grid>
       </Card>

       

       <Card className='inner-card connect mb-10' id="id-3">
       
      
    <form onSubmit={handleSubmit} className="formsubmit">
       <Grid container spacing={2}>
       <Grid item xs={12}>
           <Item>
              <h3 className='sec-title'>Connect</h3>
             </Item>
         </Grid>
        
         <Grid item md={4} xs={12}>
           <Item>
              <TextField id="standard-basic" label="Name" className='inputs' variant="standard"  value={name} onChange={(e) => setName(e.target.value)} name="name" required />
          </Item>
         </Grid>  
          <Grid item md={4} xs={12}>
           <Item>
            <TextField id="standard-basic" label="Mobile" variant="standard" className='inputs' name="mobile"  value={mobile} onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ""))} required  />
          </Item>
         </Grid>
         <Grid item md={4} xs={12}>
           <Item>
            <TextField id="standard-basic" label="Email" variant="standard" className='inputs' name="emailID"  value={emailID}  onChange={(e) => setEmail(e.target.value)} required   />
            </Item>
         </Grid>
         <Grid item md={12} xs={12}>
           <Item>
           
        <TextField
          id="standard-multiline-static"
          label="Query"
          multiline
          rows={4}
          
          variant="standard" className='inputs' name="remarks" required value={remarks}  onChange={(e) => setMessage(e.target.value)}
        />

             </Item>
         </Grid>
         <Grid item xs={12}>
           <Item>
          <Button variant="contained" type="submit">Submit</Button>

             </Item>
         </Grid>
       </Grid>
         </form>
       </Card>
      
      </div> 
    </div>
  );
}

export default Serialnumbergenuine;
