import React,  { Component, useState, useEffect }from 'react';
import '../../Common/Custom.css';
import { BrowserRouter , Link, NavLink, useNavigate, withRouter, useParams  } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DvrIcon from '@mui/icons-material/Dvr';
import EventIcon from '@mui/icons-material/Event';
import MoneyIcon from '@mui/icons-material/Money';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PlaceIcon from '@mui/icons-material/Place';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import CelebrationIcon from '@mui/icons-material/Celebration';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CallIcon from '@mui/icons-material/Call';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

 
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow:'none',
}));






function Serial(props) {  
    
  const [name, setName] = useState("");
  const [emailID, setEmail] = useState("");
  const [mobile, setMobileNumber] = useState("");
  const [remarks, setMessage] = useState("");



 const { qrCode } = useParams([]);

    
  const [offdata2, setOffData2] = useState([]);
  const offdata1 = {
    cIp:'',
    qrCode: qrCode,
    parentID: "40010"
  };
  let authToken = "J47OVAUbs5z5vMuhENUTpzfq/jxCrnqid55BpzdYm73bkgvYoi6YT8OH9Wulh1jE+nRaS3+eM1A/MlsDFraz2ECW4dDzC3Ff50WXsR7PB4qzu3h3QkHHwHusnYMVMl6uXQm+jj1IG1VPKw97uPHW5eWJ5n/5OTjW2yKkclTnmGzuVsHhHqm33nIFZiRE0gbrCZ6RXt5pxvty5+PBHQq1PJ91P/c2ZmWLjhGH/BbFSRvPsJZL9dDzOMCOw7WQYlj7pHRWWb8nW/czQhr53uQLpSY2fc4Dqj27xdMH+shxkF7TfIxN98QIYAoCIhLWlskE7+lpT/0J7gHISXQ1q3uVIFBq96cuyw+uaBuGdjB4HZr80+adCDDa+aQhly2NMp57MG/8du5B4yN0JY5EGwth9dsNUZvea6ozKyERoLbmsOA3gyQndWaOiUI1l254xV0i2LpvNDJccqHDIYlb+5QYAw==";
  
      
  const urlData='https://anandawebapi.dialerp.com/api/WebApi/AddGetScanHistory'+
  '?qrCode='+qrCode+'&latitude=&longitude=&parentID=40010'
  
  function Offdata() {
    const userInfo = JSON.parse(localStorage.getItem("user-info"));
    if (userInfo != undefined && userInfo != null && userInfo != "")
      authToken = userInfo?.response?.loginKey;

    fetch(
        urlData,
      {
        method: "POST",
        headers: {
          "Authorization-Token":authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
       // body: JSON.stringify(offdata1),
      }
    ).then((result) => {

      result.json().then((resp) => {
        console.log(resp, 'datas');
        if(resp.hasErrors){
          window.setTimeout(function() {
            window.location.href = '/not-found';
        }, 1);
        } 
        else
        setOffData2(resp);
        
      const scanHistoryRows= resp.response.scanHistorylist.length;
        if ( scanHistoryRows > 1 ){
          handleClickOpen();
        }
            //alert('hello');
           
        //}

      console.log(offdata1, 'data');

      });
    });
  }
  
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  
  const handleClickOpen4 = () => {
    setOpen2(true);
  };

  const handleClickOpen2 = () => {
    setOpen3(true);
  };
  const handleClickOpen3 = () => {
    setOpen4(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
  };

  useEffect(() => {
    Offdata();
   // setTimeout(()=>{
      //setOpen(true)
    //}, 500)

  },[]);

  const openLink = () => {
    window.location.replace('https://amzn.eu/d/2laVQDS')
  }



  let handleSubmit = async (e) => {
    e.preventDefault();
	
	 fetch
     ("https://api.dialerp.com/api/WebApi/pushCRMDealMongo/40010A1?countryCode=USA&name="+name+"&emailID="+emailID+"&mobile="+mobile+"&remarks="+remarks, {
      method: 'POST',
    } )
    .then(res => {
      //alert("Success"); 
       handleClickOpen3();
          //console.log(res.response+"---data---"+res.data)
  
        //  if(string.includes("Successfully")){
           // handleClickOpen3();
           
             
        //  }
      
    })
    .catch((error) => {
        console.log(error)
    })
  } 


  return( <div><div className="verify-product">
     <Dialog show={open}
                onHide={() => setOpen(false)}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" onBackdropClick="false"
      >
       <div className='scan-alert'>
                <strong>Did you scan this product before?</strong>
               
        <DialogActions className='ques-button'>  

              <Button variant="contained" color="success" onClick={handleClose}>Yes</Button>
              <Button variant="contained" color="error" onClick={handleClickOpen4} >No</Button>
        </DialogActions>
               </div>
      </Dialog>


      <Dialog show={open2}
         onHide={() => setOpen2(false)}
        open={open2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" onBackdropClick="false"
      >
       <div className='scan-alert'>
                <strong>Do you want to raise query for this?</strong>
               
        <DialogActions className='ques-button'>  

              <Button variant="contained" color="success" onClick={handleClickOpen2}>Yes</Button>
              <Button variant="contained" color="error" onClick={handleClose}>No</Button>
        </DialogActions>
               </div>
      </Dialog>

      
      <Dialog show={open3}
         onHide={() => setOpen3(false)}
        open={open3}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" onBackdropClick="false"
      >
       <div className='scan-alert'>
                <strong>Submit Form</strong>
              
       
                <form onSubmit={handleSubmit} className="formsubmit formsubmitt">
       <Grid container spacing={2}>
        
       <Grid item md={12} xs={12}>
        
       {offdata2?.response?.objlist.map((offitem, i) => {
        return (


               <ul className='product-detail-lst qrcodeview'>
                  <li key="{offitem}"> QR Code : {offitem.qrCode}</li>
               </ul> 
        );
    })}
        </Grid>
     
         <Grid item md={6} xs={12}>
           <Item>
              <TextField id="standard-basic" label="Name" className='inputs' variant="standard"  value={name} onChange={(e) => setName(e.target.value)} name="name" required />
          </Item>
         </Grid>  
          <Grid item md={6} xs={12}>
           <Item>
            <TextField id="standard-basic" label="Mobile" variant="standard" className='inputs' name="mobile"  value={mobile} onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ""))} required  />
          </Item>
         </Grid>
         <Grid item md={12} xs={12}>
           <Item>
            <TextField id="standard-basic" label="Email" variant="standard" className='inputs' name="emailID"  value={emailID}  onChange={(e) => setEmail(e.target.value)} required   />
            </Item>
         </Grid>
         <Grid item md={12} xs={12}>
           <Item>
           
        <TextField
          id="standard-multiline-static"
          label="Query"
          multiline
          rows={4}
          
          variant="standard" className='inputs' name="remarks" required value={remarks}  onChange={(e) => setMessage(e.target.value)}
        />

             </Item>
         </Grid>
         <Grid item xs={12}>
           <Item>
          <Button variant="contained" type="submit" color="success" onClick={handleClose}>Submit</Button>

             </Item>
         </Grid>
       </Grid>
         </form>
       
               </div>
      </Dialog>
 
      <Dialog show={open4}
                onHide={() => setOpen4(false)}
        open={open4}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" onBackdropClick="false"
      >
       <div className='scan-alert'>
                <strong>Thanks for submitting your query.</strong>
               
        <DialogActions className='ques-button'>  

              <Button variant="contained" color="success" onClick={handleClose}>Ok</Button>
        </DialogActions>
               </div>
      </Dialog>
 

      <div className="product-box">
       <Card className='card-box'>
          <Grid container spacing={2} className='mb-10'>
            <Grid item xs={12}>
            {offdata2?.response?.objlist.map((offitem, i) => {
        return (


       
              <Item><h3> {offitem.itemName}</h3></Item>
            
        );
    })}
            </Grid>
          </Grid>
          <Card className='inner-card mb-10'>
          <Grid container spacing={2}>
          
            <Grid item md={5} xs={12}>

              
            {offdata2?.response?.objlist.map((offitem, i) => {
              
            const iteNAme = offitem.itemName;
            //console.log(iteNAme, 'ite')

            if (iteNAme === "Ananda Product"){
        return (
              <Item><img src={process.env.PUBLIC_URL + '/images/logo.webp'} alt="Slide" style={{width: '200px'}} /></Item>
              
        );}
        else { return(
          <Item><img src={process.env.PUBLIC_URL + '/images/file-1660022359197.png'} alt="Slide" style={{width: '200px'}} /></Item>)
        }
      })}
            </Grid>
            <Grid item md={7} xs={12}>
              <Item>
             
                
                {offdata2?.response?.objlist.map((offitem, i) => {
 const iteNAme = offitem.itemName;
 if (iteNAme === "Ananda Product"){
        return (
<>
          <div className='scan-status genuine'><CelebrationIcon/> Genuine Product</div>
          <Divider/>
               <ul className='product-detail-lst'>
                 {/* <li key="{offitem}"><DvrIcon /> Batch No : {offitem.batchNo}</li>
                  <li key="{offitem2}"><EventIcon /> Expiry Date : {offitem.strExpiryDate}</li>
                  <li key="{offitem3}"><MoneyIcon /> MRP : {offitem.mrp}</li>
                  <li key="{offitem4}"><WarehouseIcon /> Manufacture Date : {offitem.strMfgDate}</li>*/}
                  <li key="{offitem5}"><PlaceIcon /> Produced At : {offitem.plantName}</li>
               </ul> </>
        );}


        else {

          return(<>
          
          <div className='scan-status genuine'><CelebrationIcon/> Genuine Product</div>
          <Divider/>
          <ul className='product-detail-lst'>
           <li key="{offitem}"><DvrIcon /> Batch No : {offitem.batchNo}</li>
             <li key="{offitem2}"><EventIcon /> Expiry Date : {offitem.strExpiryDate}</li>
             <li key="{offitem3}"><MoneyIcon /> MRP : {offitem.mrp}</li>
             <li key="{offitem4}"><WarehouseIcon /> Manufacture Date : {offitem.strMfgDate}</li>
             <li key="{offitem5}"><PlaceIcon /> Produced At : {offitem.plantName}</li>
          </ul> 
          </>
          
          )
        }
    })}
               
              


              </Item>
            </Grid>
          </Grid>
          </Card>


          <Card className='inner-card mb-10'>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Item>
              <div className='scantitle'>Scan Hisitory</div>

            <table className='scanhistorytable css-serial' cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>Sr.</th>
                    <th> Scan Date</th>
                    <th>IP Address</th>
                </tr>
                </thead>
                <tbody>
                {offdata2?.response?.scanHistorylist.map((offitem, i) => {
                return (
                <tr>
                    <td></td>
                    <td>{offitem.scanDt}-{offitem.scanTime}</td>
                    <td>{offitem.cIp}</td>
                </tr>
                    );
                })}
                </tbody>
            </table>
              </Item>
            </Grid>
          </Grid>
          </Card>


          <Card className='inner-card mb-10'>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Item>
              <div className='helplinetitle'>Helpline For Any Query  <a href="tel:+91-9582 77 5555"><CallIcon/> +91-9582 77 5555</a></div>

              </Item>
            </Grid>
          </Grid>
          </Card>


       </Card>

       <Grid container spacing={2} className="mb-20 mb-auto button-group">          
          <Grid item xs={12}>
                <Stack spacing={2} direction="row" className="stacks-button-group">
                <HashLink smooth to="#id-1" activeclassname="active"> <Button variant="outlined">Discover</Button></HashLink>
                <HashLink smooth to="#id-2" activeclassname="active"> <Button variant="outlined">Learn</Button></HashLink>
                  <Button variant="outlined" onClick={openLink}>Buy</Button>
                    <HashLink smooth to="#id-3" activeclassname="active"> <Button variant="outlined">Connect</Button></HashLink>
                </Stack>
          </Grid>
         </Grid>

       <Card className='inner-card discover mb-10' id="id-1">
       
          <Grid container spacing={2}>
          
            <Grid item md={6} xs={12}>
              <Item>
                  <Card>
                  <p className='video-title'>See how your favourite Ghee is prepared?</p>
                  <iframe width="94%" height="180px" src="https://www.youtube.com/embed/yRUUSoxHHs0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </Card>
                </Item>
            </Grid>
            <Grid item md={6} xs={12}>
              <Item>                
                <Card>
                  <p className='video-title'>Ananda Business Associates Awards</p>
                  <iframe width="94%" height="180px" src="https://www.youtube.com/embed/XXDx2LxF9C8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </Card>
              </Item>
            </Grid>
            <Grid item md={6} xs={12}>
              <Item>                
                <Card>
                  <p className='video-title'>Besan ke laddu with Ananda Ghee</p>
                  <iframe width="94%" height="180px" src="https://www.youtube.com/embed/Gqnc7TtmtE4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Card>
              </Item>
            </Grid>
            <Grid item md={6} xs={12}>
              <Item>                
                <Card>
                  <p className='video-title'>Our other products</p>
                  <a href="https://ananda.in/main-category/" target="_blank"><img src={process.env.PUBLIC_URL + '/images/ananda-products.webp'} alt="Slide" style={{width: '96%'}} /></a>
                </Card>
              </Item>
            </Grid>
            
          </Grid>
          </Card>

          <Card className='inner-card learn mb-10' id="id-2">
       
       <Grid container spacing={2}>
       
         <Grid item xs={12}>
           <Item>
              <h3 className='sec-title'>Learn</h3>
               <p className='video-title'>Ananda Ghee is an ethnic product made by our dairies with decades of experience. Our Ghee is a rich source of vitamins A and D, antioxidants, Omega 3 and CLA, essential for vision, body growth, bone metabolism and immune system.

</p>
              
             </Item>
         </Grid>
       </Grid>
       </Card>

       

       <Card className='inner-card connect mb-10' id="id-3">
         
    <form onSubmit={handleSubmit} className="formsubmit">
       <Grid container spacing={2}>
       <Grid item xs={12}>
           <Item>
              <h3 className='sec-title'>Connect</h3>
             </Item>
         </Grid>
        
         <Grid item md={4} xs={12}>
           <Item>
              <TextField id="standard-basic" label="Name" className='inputs' variant="standard"  value={name} onChange={(e) => setName(e.target.value)} name="name" required />
          </Item>
         </Grid>  
          <Grid item md={4} xs={12}>
           <Item>
            <TextField id="standard-basic" label="Mobile" variant="standard" className='inputs' name="mobile"  value={mobile} onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ""))} required  />
          </Item>
         </Grid>
         <Grid item md={4} xs={12}>
           <Item>
            <TextField id="standard-basic" label="Email" variant="standard" className='inputs' name="emailID"  value={emailID}  onChange={(e) => setEmail(e.target.value)} required   />
            </Item>
         </Grid>
         <Grid item md={12} xs={12}>
           <Item>
           
        <TextField
          id="standard-multiline-static"
          label="Query"
          multiline
          rows={4}
          
          variant="standard" className='inputs' name="remarks" required value={remarks}  onChange={(e) => setMessage(e.target.value)}
        />

             </Item>
         </Grid>
         <Grid item xs={12}>
           <Item>
          <Button variant="contained" type="submit">Submit</Button>

             </Item>
         </Grid>
       </Grid>
         </form>
       </Card>
      
      </div> 
    </div>
    </div>
);
}


export default Serial;
