import React, {Component, useEffect, useState } from "react";
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import './../Common/Custom.css';
import { render } from 'react-dom';
import Button from '@mui/material/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LanguageIcon from '@mui/icons-material/Language';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import FaxIcon from '@mui/icons-material/Fax';
import TollIcon from '@mui/icons-material/Toll';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Carousel from 'react-bootstrap/Carousel';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Image } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))



function Home() {

  
  return (
    <>
      <div className="homecarousal">
       <div className="header">
        <img
          className="d-block"
          src={process.env.PUBLIC_URL + '/images/logo.webp'}
          alt="Ananda"
        />
       </div>
        <Carousel>
      <Carousel.Item>
   
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + '/images/banner1.webp'}
          alt="Ananda"
        />
        <Carousel.Caption>
          <p>Untouched Delivery</p>
          <h3>Download <b>Ananda <br /> App Now!</b></h3>
          <Stack direction="row" spacing={2} className="appimg">
           
             <Item>                
             <img
                src={process.env.PUBLIC_URL + '/images/play-store.webp'}
                alt="Ananda"
                />
             </Item>
          </Stack>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + '/images/banner2.webp'}
          alt="Ananda"
        />
        <Carousel.Caption>
          <p>Untouched Delivery</p>
          <h3>Download <b>Ananda <br /> App Now!</b></h3>
          <Stack direction="row" spacing={2} className="appimg">
           
             <Item>                
             <img
                src={process.env.PUBLIC_URL + '/images/play-store.webp'}
                alt="Ananda"
                />
             </Item>
          </Stack>
        </Carousel.Caption>

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + '/images/banner3.webp'}
          alt="Ananda"
        />

        <Carousel.Caption>
          <p>Untouched Delivery</p>
          <h3>Download <b>Ananda <br /> App Now!</b></h3>
          <Stack direction="row" spacing={2} className="appimg">
           
             <Item>                
             <img
                src={process.env.PUBLIC_URL + '/images/play-store.webp'}
                alt="Ananda"
                />
             </Item>
          </Stack>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
      
      </div>
      


        

        



    </>
  );
}


export default Home;
